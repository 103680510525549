// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-active-living-js": () => import("./../../../src/pages/active-living.js" /* webpackChunkName: "component---src-pages-active-living-js" */),
  "component---src-pages-albums-sanity-albums-slug-current-js": () => import("./../../../src/pages/albums/{sanityAlbums.slug__current}.js" /* webpackChunkName: "component---src-pages-albums-sanity-albums-slug-current-js" */),
  "component---src-pages-blank-template-js": () => import("./../../../src/pages/blankTemplate.js" /* webpackChunkName: "component---src-pages-blank-template-js" */),
  "component---src-pages-care-directory-js": () => import("./../../../src/pages/care-directory.js" /* webpackChunkName: "component---src-pages-care-directory-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diabetes-js": () => import("./../../../src/pages/diabetes.js" /* webpackChunkName: "component---src-pages-diabetes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-nutrition-plant-medicines-js": () => import("./../../../src/pages/nutrition-plant-medicines.js" /* webpackChunkName: "component---src-pages-nutrition-plant-medicines-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-custom-page-template-js": () => import("./../../../src/templates/CustomPageTemplate.js" /* webpackChunkName: "component---src-templates-custom-page-template-js" */)
}

