module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"borealis-gatsby-sanity","short_name":"Gatsby Sanity","start_url":"/","background_color":"white","theme_color":"#749833","display":"minimal-ui","icon":"src/images/Header-Logo-Crop.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"653ad994a029636ea6cdde8f4c7abd46"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
